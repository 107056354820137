import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { authRequestStart } from '../redux/actions/Auth'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import Layout from '../scene/Layout'
import IndexScene from '../scene/IndexScene'

const mapStateToProps = ({ auth }) => ({
  authData: auth,
})

const mapDispatchToProps = {}

const RoutesComponent = ({ authRequest }) => {
  return (
    <BrowserRouter>
      <Switch>
        <Route
          render={history => (
            <Layout history={history}>
              <Route exact path={'/:id'} component={IndexScene} />
            </Layout>
          )}
        />
      </Switch>
    </BrowserRouter>
  )
}
export default connect(mapStateToProps, mapDispatchToProps)(RoutesComponent)
