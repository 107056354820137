import {BASE_URL} from "./baseUrl";
import * as axios from "axios";


export const configureApi = (store) => {
    const instance = axios.create({
        baseURL: BASE_URL,
        headers: {
            "Accept": "application/json",
            "Access-Control-Allow-Origin": "*",
            "X-Requested-With": "XMLHttpRequest",
            "Access-Control-Allow-Methods" : "GET,POST,PUT,DELETE,OPTIONS",
            "Access-Control-Allow-Headers": "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
            "Authorization" : "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyaWQiOjcsInJvbGUiOiJhZG1pbmlzdHJhdG9yIiwiaWF0IjoxNjA0NTA5MDc2fQ.EhoBVOyOP_Hk425L0jtKJ1iypwkBNsz7zDGZOwjunu8"
        },
        withCredentials: true
    });

    instance.interceptors.response.use(
        (response) => {
            if (response.data.error && response.data.error.code === 401) {
                store.dispatch(response);
            }
            return response;
        },
        (error) => {
            if (error.response.status === 401) {
                store.dispatch(error);
            }
            return Promise.reject(error);
        });

    return instance;
};