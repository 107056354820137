import React from 'react'
import styles from './Navbar.module.css'
import logo from '../../img/main/navbar/logo.svg'
import burger from '../../img/main/navbar/burger.png'
import moment from 'moment'
import 'moment/locale/ru'
moment.locale('ru')

const month = [
  'все',
  'января',
  'февраля',
  'марта',
  'апреля',
  'мая',
  'июня',
  'июля',
  'августа',
  'сентября',
  'октября',
  'ноября',
  'декабря',
]

const Navbar = () => {
  const now = new Date()

  return (
    <div className={styles.wrapper}>
      <div className={styles.logo_block}>
        <img src={logo} alt='' className={styles.logo} />
        <h2>Заказы на {moment(now).format('DD') + '-е ' + month[moment(now).format('M')]}</h2>
      </div>
      <div className={styles.button_block}>
        <h2>{moment(now).format('HH:mm')}</h2>
        <span className={styles.version}>15.02.1</span>
        <button>
          <img src={burger} alt='burger button' />
        </button>
      </div>
    </div>
  )
}

export default Navbar
