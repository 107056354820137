import React, { useMemo } from 'react'
import { configureApi } from './constants/configureApi'
import RoutesComponent from './routing/Routes'
import './global.css'
import Paho from 'paho-mqtt'
import { store } from './redux/store'
import { useDispatch } from 'react-redux'
import { authRequestStart } from './redux/actions/Auth'
import sound from './sound/blissful-bell.wav'

export const api = configureApi(store)

const App = () => {

  const pathname = window.location.pathname?.split('/')[1]
  const dispatch = useDispatch()
  const host = 'mangocafe.ru'
  const port = Number(15676)

  const client = useMemo(() => {
    let client = new Paho.Client(host, port, '/ws', `client${Date.now()}`)

    const options = {
      timeout: 3,
      cleanSession: true,
      onSuccess: onConnect,
      onFailure: onFailureFunc,
      keepAliveInterval: 20,
      userName: 'react',
      useSSL: true,
      password: 'LBanrB2ku89q',
    }

    client.onConnectionLost = onConnectionLost
    client.onMessageArrived = onMessageArrived

    client.connect(options)
    return client
  }, [host])

  // called when the client connects
  function onConnect() {
    // Once a connection has been made, make a subscription and send a message.
    var soptions = {
      qos: 0,
    }
    client.subscribe(`/api/orders/${pathname}`, soptions)
    console.log('Subscribe is running')
  }

  // called when the client loses its connection
  function onConnectionLost(responseObject) {
    if (responseObject.errorCode !== 0) {
      console.log('onConnectionLost:' + responseObject.errorMessage + 'error')
    } else console.log('connected is running')
  }
  // called when a message arrives
  function onMessageArrived(message) {
    console.log('Сообщение с заказом:', message.payloadString)
    if ((JSON.parse(message.payloadString).orderId !== null) && (JSON.parse(message.payloadString).orderStatus == 'sent')) {
      console.log('true');
      var popsound = new Audio(sound)
      popsound.load()
      popsound.play()
      dispatch(authRequestStart(pathname))
    }
  }

  function onFailureFunc(err) { console.log(err.invocationContext+' '+err.errorCode+':'+err.errorMessage);}

  return <RoutesComponent />
}

export default App
