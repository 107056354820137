import arrow from "../img/main/icon/arrow.svg";

export const SampleNextArrow = (props) => {
    const { className, onClick } = props;
    return (
        <div className={className} onClick={onClick}>
            <img src={arrow} alt="arrow"/>
        </div>
    );
}

export const SamplePrevArrow = (props) => {
    const { className, onClick } = props;
    return (
        <div className={className} onClick={onClick}>
            <img src={arrow} alt="arrow"/>
        </div>
    );
}