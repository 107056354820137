import React from 'react';
import {connect} from 'react-redux';
import Navbar from "../../components/Navbar";

// function mapStateToProps(state) {

// }

// function mapDispatchToProps(dispatch) {

// }

const Layout = ({children}) => {
    return (
        <React.Fragment>
            <Navbar />
            {children}
        </React.Fragment>
    );
}
export default connect()(Layout);