import {AUTH, ERROR, START, SUCCESS} from "../../types/action-types";

export const authRequestStart = (data) => ({
    type: `${AUTH}${START}`,
    payload: data
})

export const authRequestSuccess = (data) => ({
    type: `${AUTH}${SUCCESS}`,
    payload: data
})

export const authRequestError = (data) => ({
    type: `${AUTH}${ERROR}`,
    payload: data
})