import { applyMiddleware, createStore } from 'redux'
import rootSaga from '../sagas/rootSaga'
import createSagaMiddleware from 'redux-saga'
import { rootReducer } from '../reducers/rootReducer'
import { composeWithDevTools } from 'redux-devtools-extension'

const initialState = {}

export const configureStore = () => {
  const sagaMiddleware = createSagaMiddleware()

  const store = createStore(rootReducer, initialState, composeWithDevTools(applyMiddleware(sagaMiddleware)))

  sagaMiddleware.run(rootSaga)

  return store
}

export const store = configureStore()
