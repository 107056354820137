import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import styles from './Index.module.css'
import Slider from 'react-slick'
import Card from '../../components/Slider/Card'
import { settings } from '../../constants/settingSlider'
import { authRequestStart } from '../../redux/actions/Auth'
import Modal from '../../components/Modal'
import { orderStatusRequest } from '../../redux/actions/OrderStatus'

const mapStateToProps = ({ orderData }) => ({
  orderData: orderData.filter(item => {
    let finishedOrders = item.orders.filter(item => item.finished)

    return finishedOrders.length !== item.orders.length
  }),
})

const mapDispatchToProps = {
  authRequest: authRequestStart,
  sendStatus: orderStatusRequest,
}

const IndexScene = ({ orderData, authRequest, sendStatus }) => {
  const [modalData, setModalData] = useState(false)
  const [visibleModal, setVisibleModal] = useState(false)
  const pathname = window.location.pathname?.split('/')[1]

  useEffect(() => {
    if (!!pathname) {
      authRequest(pathname)
    }
  }, [pathname])

  return (
    <div className={styles.card_list}>
       {!!orderData.length ? (
        <Slider {...settings}>
          {orderData && orderData.map((item, i) => 
          <Card 
            key={i}
            data={item} 
            setModalData={setModalData} 
            setVisibleModal={setVisibleModal} 
            sendRequest={order => console.log(order, 'order')}
            modalData={modalData}
            sendStatus={sendStatus}
            pathname={pathname}
            />)}

        </Slider>
      ) : (
        <div className={styles.no_found}>
          <h1>Нет заказов</h1>
        </div>
      )}
      {false && (
        <Modal
          setVisibleModal={setVisibleModal}
          data={modalData}
          sendRequest={order => {
            if (order === 'all') {
              modalData.arrayIds.forEach(e => sendStatus({ ...modalData, id: e, restid: parseInt(pathname) }))
            } else {
              sendStatus({ ...modalData, restid: parseInt(pathname) })
            }
            setVisibleModal(false)
          }}
        />
      )}
    </div>
  )
}
export default connect(mapStateToProps, mapDispatchToProps)(IndexScene)
