import { call, put, takeLatest } from '@redux-saga/core/effects'
import { ORDER_STATUS, START } from '../../types/action-types'
import { sendOrderStatus } from '../../../api/Order'
import { orderStatusError, orderStatusSuccess } from '../../actions/OrderStatus'
import { authRequestStart } from '../../actions/Auth'

export default function* orderStatusWorker() {
  yield takeLatest(`${ORDER_STATUS}${START}`, orderWorker)
}

function* orderWorker({ payload: data }) {
  console.log(data)
  try {
    const response = yield call(sendOrderStatus, data)
    yield put(orderStatusSuccess(response.data.data))
    yield put(authRequestStart(data.restid))
  } catch (error) {
    yield put(orderStatusError(error))
  }
}
