import {AUTH, SUCCESS} from "../../types/action-types";

const initialState = [];

export const authReducer = (state = initialState, action) => {
    switch (action.type) {

        case `${AUTH}${SUCCESS}`:
            return action.payload;

        default:
            return state;
    }
};