import {ERROR, ORDER_STATUS, START, SUCCESS} from "../../types/action-types";

export const orderStatusRequest = (data) => ({
    type: `${ORDER_STATUS}${START}`,
    payload: data
})

export const orderStatusSuccess = (data) => ({
    type: `${ORDER_STATUS}${SUCCESS}`,
    payload: data
})

export const orderStatusError = (data) => ({
    type: `${ORDER_STATUS}${ERROR}`,
    payload: data
})