import {AUTH, START} from "../../types/action-types";
import {authRequestError, authRequestSuccess} from "../../actions/Auth";
import {auth} from "../../../api/Auth";
import {call, put, takeLatest} from "@redux-saga/core/effects";

export default function* searchWatcher() {
    yield takeLatest(`${AUTH}${START}`, searchWorker);
}

function* searchWorker({payload: id}) {
    try {
        const response = yield call(auth, id);
        yield put(authRequestSuccess(response.data.data));
    } catch (error) {
        yield put(authRequestError(error));
    }
}